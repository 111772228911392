* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #06101D;
}

.transition {
  transition: .3s ease-in-out;
}

.bg-dark-blue {
  background-color: #06101D;
}

.bg-blue {
  background-color: #223F5D;
}

.bg-white {
  background-color: white;
}

.bg-transparent {
  background-color: transparent;
}

.border-transparent {
  border: 3px solid transparent;
}

.border-blue {
  border: 3px solid #223F5D;
}

.border-light-blue {
  border: 3px solid #3388FF;
}


.light-blue {
  color: #3388FF;
}


.border-none {
  border: none;
}

.blue {
  color: #223F5D;
}


.dark-blue {
  color: #06101D;
}

.white {
  color: white;
}

.gray {
  color: #8B8E92;
}

.h1 {
  font-size: 24px;
}

.h2 {
  font-size: 16px;
}

.sub {
  font-size: 12px;
}

.bold {
  font-weight: 700;
}

.normal {
  font-weight: 500;
}

.thin {
  font-weight: 300;
}

.input {
  padding: 0;
  width: 100%;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  font-weight: 700;
  color: #8B8E92;
}

.button {
  border-radius: 2px;
  padding: 8px 12px;
}

.button:hover {
  cursor: pointer;
}

.button-2 {
  border-radius: 2px;
  padding: 14px 12px;
}

.button:hover {
  cursor: pointer;
}

.button-2:hover {
  cursor: pointer;
}

button[disabled] {
  opacity: .5;
}

button[disabled]:hover {
  cursor: default;
}

.inactive {
  opacity: .4;
}

.bg-error {
  background-color: #c40534 !important;
}

.error {
  color: #c40534 !important;
}

.border-error {
  border: 3px solid #c40534 !important;
}

.hide {
  display: none;
}

.show {
  display: inline;
}

.divider {
  height: 2px;
  background-color: white;
  width: 100%;
}

#loader {
  height: 150px;
  width: 150px;
}

.h0 {
  font-size: 48px;
}