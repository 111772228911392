.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  border-radius: 6px;
  width: 100%;
}

.input-container>.icon>.img {
  height: 24px;
}

.input-container>.content {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex-grow: 1;
}

#py-form {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 48px;
  width: auto;
  gap: 36px;
  overflow-y: none;
}

.single {
  margin-right: 0px;
  /*Fix later*/
}

.form-row-header {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-row {
  display: flex;
  width: auto;
  gap: 12px;
  flex-direction: column;
}

#py-form>.form-row>.row {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}

#py-container {
  width: 100vw;
  height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
  overflow-y: none;
}

.button-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

#stripe-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#stripe-container>div {
  display: flex;
  align-items: center;
  gap: 8px;
}

#stripe-container>.stripe-box {
  gap: 4px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
}

@media screen and (max-width: 600px) {
  #py-form {
    height: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 48px !important;
    padding-bottom: 48px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    width: calc(100% - 32px);
    gap: 36px;
    overflow-y: scroll;
  }

  #py-container {
    width: 100vw;
    padding: 0 !important;
    height: calc(100vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: none;
  }
  .mobile-hide{
    display:none;
  }
}