#failure-container {
    width: 100vw;
    height: calc(100vh - 36px);
    padding-top: 0;
    padding-bottom: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#failure-container-inner {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}


#failure-icon {
    width: 25px;
    height: 25px;
}

#failure-banner {
    width: 100%;
    height: 150px;
    background-image: url('../../assets/banner.jpg');
    background-position: center 30%;
    background-size: cover;
    background-repeat: no-repeat;
    display:flex;
    justify-content: center;
    align-items: center;
}

#failure-banner > img{
    width:150px;
}

#failure-content {
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 18px;
    line-height: 1.25rem;
}

#failure-content>div {
    display: flex;
    gap: 12px;
    align-items: center;
}

@media screen and (max-width: 600px) {

    #failure-container-inner {
        width: 90%;
    }
    #failure-content {
        line-height: 1.5rem;
    }

    .more{
        line-height: 2rem;
    }
}